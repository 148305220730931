<template>
  <el-tabs :class="mode ? 'mode__lockday' : 'mode__locknight'" :tab-position="tabPosition">
      <el-tab-pane :label="$t('message.main')">
          <el-card :class="mode ? 'el__cardday' : 'el__cardnight'" v-loading="loadingDate">
              <el-col :span="12">
                  <el-form label-position="left" :model="settings" label-width="340px" size="mini">
                      <el-form-item :class="mode ? '' : 'label__night'" :label="$t('Devices Count Type')">
                          <el-select :class="mode ? 'mode_1' : 'mode__2'" v-model="settings.device_type"
                              :placeholder="$t('Devices Count Type')">
                              <el-option v-for="item in devices" :key="item.value" :label="item.label"
                                  :value="item.value">
                              </el-option>
                          </el-select>
                      </el-form-item>
                      <el-form-item :class="mode ? '' : 'label__night'" :label="$t('1-yillik tibbiy tatil kuni')">
                          <el-input :class="mode ? 'mode_1' : 'mode__2'" v-model="settings.yearly_sick_leave_days"
                           autocomplete="off" placeholder="5"></el-input>
                      </el-form-item>
                      <el-form-item :class="mode ? '' : 'label__night'" :label="$t('message.holiday_work_contribution')">
                        <el-select :class="mode ? 'mode_1' : 'mode__2'" v-model="settings.holiday_work_contribution" :placeholder="$t('message.holiday_work_contribution')">
                          <el-option  key="1" label="1"
                                :value="1">
                            </el-option>  
                          <el-option  key="1.25" label="1.25"
                                :value="1.25">
                            </el-option>
                            <el-option  key="1.5" label="1.5"
                                :value="1.5">
                            </el-option>
                            <el-option  key="1.75" label="1.75"
                                :value="1.75">
                            </el-option>
                            <el-option  key="2" label="2"
                                :value="2">
                            </el-option> 
                            <el-option  key="2.25" label="2.25"
                                :value="2.25">
                            </el-option>
                            <el-option  key="2.5" label="2.5"
                                :value="2.5">
                            </el-option>
                            <el-option  key="2.75" label="2.75"
                                :value="2.75">
                            </el-option>
                            <el-option  key="3" label="3"
                                :value="3">
                            </el-option>
                        </el-select>
                      </el-form-item>
                      <el-form-item :class="mode ? '' : 'label__night'" :label="$t('message.sanction_type')" v-if="sanction_types&&sanction_types.length">
                        <el-select :class="mode ? 'mode_1' : 'mode__2'" v-model="settings.sanction_type_id" :placeholder="$t('message.sanction_type')">
                          <el-option
                              v-for="item in sanction_types"
                              :key="'sanction_type_'+item.id"
                              :label="item.name"
                              :value="item.id"
                          >
                          </el-option>
                        </el-select>
                      </el-form-item>
                      <el-form-item :class="mode ? '' : 'label__night'"
                          :label="$t('message.by_connecting_to_the_device')">
                            <el-switch v-model="settings.by_connecting_to_the_device"
                              :active-text="$t('message.yes')"
                              :inactive-text="$t('message.no')">
                          </el-switch>
                        </el-form-item>
                      <el-form-item :class="mode ? '' : 'label__night'">
                          <el-button type="primary" round @click="submit">{{ $t('message.save') }}</el-button>
                      </el-form-item>
                  </el-form>
              </el-col>
          </el-card>
      </el-tab-pane>
      <el-tab-pane :label="$t('Telegram')">
          <el-card :class="mode ? 'el__cardday' : 'el__cardnight'" v-loading="loadingDate">
                  <el-form label-position="left" :model="settings" label-width="340px" size="mini">
                    <el-row :gutter="20">
                      <el-col :span="12">
                        <el-form-item :class="mode ? '' : 'label__night'"
                        :label="$t('message.avance')+' ('+$t('message.send_channel')+')'">
                          <el-switch v-model="settings.send_channel_salary"
                            :active-text="$t('message.yes')"
                            :inactive-text="$t('message.no')">
                        </el-switch>
                      </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item :class="mode ? '' : 'label__night'"
                        :label="$t('message.take_assents')+' ('+$t('message.send_channel')+')'">
                          <el-switch v-model="settings.send_channel_take_assent"
                            :active-text="$t('message.yes')"
                            :inactive-text="$t('message.no')">
                        </el-switch>
                      </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item :class="mode ? '' : 'label__night'"
                        :label="$t('message.vacation')+' ('+$t('message.send_channel')+')'">
                          <el-switch v-model="settings.send_channel_vacation"
                            :active-text="$t('message.yes')"
                            :inactive-text="$t('message.no')">
                        </el-switch>
                      </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item :class="mode ? '' : 'label__night'"
                        :label="$t('Ежедневные отчеты')+' ('+$t('message.send_channel')+')'">
                          <el-switch v-model="settings.send_channel_daily_statistics"
                            :active-text="$t('message.yes')"
                            :inactive-text="$t('message.no')">
                        </el-switch>
                      </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item :class="mode ? '' : 'label__night'"
                        :label="$t('message.vacancy')+' ('+$t('message.send_channel')+')'">
                          <el-switch v-model="settings.send_channel_vacancies"
                            :active-text="$t('message.yes')"
                            :inactive-text="$t('message.no')">
                        </el-switch>
                      </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item :class="mode ? '' : 'label__night'"
                        :label="$t('message.active_work')+' ('+$t('message.send_channel')+')'">
                          <el-switch v-model="settings.send_channel_active_people"
                            :active-text="$t('message.yes')"
                            :inactive-text="$t('message.no')">
                        </el-switch>
                      </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item :class="mode ? '' : 'label__night'"
                        :label="$t('message.bonuses')+' ('+$t('message.send_channel')+')'">
                          <el-switch v-model="settings.send_channel_bonuse"
                            :active-text="$t('message.yes')"
                            :inactive-text="$t('message.no')">
                        </el-switch>
                      </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item :class="mode ? '' : 'label__night'"
                        :label="$t('message.sanction')+' ('+$t('message.send_channel')+')'">
                          <el-switch v-model="settings.send_channel_penalty"
                            :active-text="$t('message.yes')"
                            :inactive-text="$t('message.no')">
                        </el-switch>
                      </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item :class="mode ? '' : 'label__night'"
                        :label="$t('message.application')+' ('+$t('message.send_channel')+')'">
                          <el-switch v-model="settings.send_channel_application"
                            :active-text="$t('message.yes')"
                            :inactive-text="$t('message.no')">
                        </el-switch>
                      </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item :class="mode ? '' : 'label__night'"
                        :label="$t('message.explanatory_letters')+' ('+$t('message.send_channel')+')'">
                          <el-switch v-model="settings.send_channel_explanatory_letter"
                            :active-text="$t('message.yes')"
                            :inactive-text="$t('message.no')">
                        </el-switch>
                      </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item :class="mode ? '' : 'label__night'"
                          :label="$t('message.online_works')+' ('+$t('message.send_channel')+')'">
                            <el-switch v-model="settings.send_channel_online_work"
                              :active-text="$t('message.yes')"
                              :inactive-text="$t('message.no')">
                          </el-switch>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item :class="mode ? '' : 'label__night'"
                          :label="$t('message.staff')+' ('+$t('message.send_channel')+')'">
                            <el-switch v-model="settings.send_channel_staff"
                              :active-text="$t('message.yes')"
                              :inactive-text="$t('message.no')">
                          </el-switch>
                        </el-form-item>
                      </el-col>
                      <el-col :span="24">
                        <div class="text-center">
                          <el-button type="primary" size="mini" round @click="submit">{{ $t('message.save') }}</el-button>
                        </div>
                       
                      </el-col>
                    </el-row>
                     
                  </el-form>
             
          </el-card>
      </el-tab-pane>
      <el-tab-pane :label="$t('Permissions')">
        <el-card :class="mode ? 'el__cardday' : 'el__cardnight'" v-loading="loadingDate">
                <el-form label-position="left" :model="settings" label-width="340px" size="mini">
                  <el-row :gutter="20">
                    <el-col :span="24">
                      <el-form-item  :class="mode ? '' : 'label__night'" :label="$t('Ishlash Sanasi')" v-if="user&&user.name=='Super Admin'">
                        <el-date-picker
                          v-model="settings.limit_date"
                            @input="dispatch"
                            type="date"
                            :placeholder="$t('message.datas')"
                            :size="'small'"
                            :format="'dd.MM.yyyy'"
                            :value-format="'yyyy-MM-dd'"
                          >
                      </el-date-picker>
                    </el-form-item>
                      <div class="text-center">
                        <el-button type="primary" size="mini" round @click="submit">{{ $t('message.save') }}</el-button>
                      </div>
                    </el-col>
                  </el-row>
                   
                </el-form>
           
        </el-card>
    </el-tab-pane>
     </el-tabs>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  data() {
      return {
          tabPosition: 'left',
          loadingDate:false,
          check: [],
          sanction_types :[],
          settings: {
              device_type: 'single',
              limit_date:null,
              yearly_sick_leave_days:5,
              holiday_work_contribution:1,
              advance_salary_procent:0,
              sanction_type_id:3,
              by_connecting_to_the_device:false,
              send_channel_daily_statistics:false,
              send_channel_salary:false,
              send_channel_vacation:false,
              send_channel_take_assent:false,
              send_channel_vacancies:false,
              send_channel_explanatory_letter:false,
              send_channel_active_people:false,
              send_channel_bonuse:false,
              send_channel_penalty:false,
              send_channel_application:false,
              send_channel_online_work:false,
              send_channel_additional_work:false,
              send_channel_staff:false,
          },
          devices: [
            {
              value: 'single',
              label: 'Single Device'
            },
            {
                value: 'multple',
                label: 'Multple Devices'
            }],
          is_can_reload: false,
      }
  },

  created() {
      this.sanctionType().then(res => {
          this.sanction_types = res;
          // console.log(res);
      });
      this.getList()
          .then(res => {
              this.settings.device_type = res.data.device_type;
              this.settings.limit_date = res.data.limit_date?res.data.limit_date:null;
              this.settings.yearly_sick_leave_days = res.data.yearly_sick_leave_days;
              this.settings.holiday_work_contribution = res.data.holiday_work_contribution;
              this.settings.send_channel_salary = res.data.send_channel_salary;
              this.settings.by_connecting_to_the_device = res.data.by_connecting_to_the_device;
              this.settings.send_channel_vacancies = res.data.send_channel_vacancies;
              this.settings.send_channel_daily_statistics = res.data.send_channel_daily_statistics;
              this.settings.send_channel_vacation = res.data.send_channel_vacation;
              this.settings.send_channel_take_assent = res.data.send_channel_take_assent;
              this.settings.send_channel_application = res.data.send_channel_application;
              this.settings.send_channel_penalty = res.data.send_channel_penalty;
              this.settings.send_channel_bonuse = res.data.send_channel_bonuse;
              this.settings.send_channel_active_people = res.data.send_channel_active_people;
              this.settings.send_channel_explanatory_letter = res.data.send_channel_explanatory_letter;
              this.settings.send_channel_online_work = res.data.send_channel_online_work;
              this.settings.send_channel_additional_work = res.data.send_channel_additional_work;
              this.settings.send_channel_staff = res.data.send_channel_staff;
              this.settings.sanction_type_id = res.data.sanction_type_id;
          })
          .catch(err => {
              this.$alert(err)
          })
  },
  computed: {
      ...mapGetters({
          user: 'auth/role',
          mode: 'MODE'
      }),
  },
  methods: {
      ...mapActions({
          save: 'settingApp/store',

          getList: 'settingApp/index',
          sanctionType: 'sanctionType/index',
      }),

      // checkBoxChange() {
      //     if (this.settings.control_materials_give.length == 2) {
      //         this.check[0] = this.settings.control_materials_give[1];
      //         this.settings.control_materials_give = [];
      //         this.settings.control_materials_give = this.check;
      //     }
      // },
      dispatch(e) {
        this.$emit("input", e);
        this.value = e;
      },
      submit() {
        this.loadingDate=true;
          this.save(this.settings)
              .then(res => {
                  // localStorage.setItem('sys_language', this.settings.system_language);

                  // this.$alert(res);

                  this.is_can_reload = true;

                  if (this.is_can_reload) {
                    this.loadingDate=false;

                  }
              })
              .catch(err => {
                  this.$alert(err)
              })
      },
  }
}
</script>
<style>
.el-select {
  width: 100% !important;
}
</style>
